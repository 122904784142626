<template>
  <div id="comments-details">
    <b-card no-body class="mb-0">
      <b-row class="m-2">
        <b-col cols="12">
          <comments-form-edit :commentsingle="commentsingle"></comments-form-edit>
        </b-col>
      </b-row>
    </b-card>
    
  </div>
</template>

<script>
  import CommentsFormEdit from './components/CommentsFormEdit'

  export default{
    name: 'CommentsDetails',
    components: {CommentsFormEdit},
    props: ['commentsingle'],
    data() {
      return {
      }
    },
  }
</script>