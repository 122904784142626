<template>
  <div>
     <b-form>

      <b-row>

        <b-col xl="6" sm="12">
            <b-form-group label="Nombre cliente" label-for="name">
              <b-form-input id="name"
                placeholder="Ingrese el nombre"
                v-model="editForm.name"
                name="name"
                :state="nameErrors.length > 0 ? false:null"
                @input="$v.editForm.name.$touch()"
                />
              <small class="text-danger">{{ nameErrors[0] }}</small>
            </b-form-group>
          </b-col>

          <b-col xl="6" sm="12">
            <b-form-group label="Proyecto" label-for="project">
              <v-select
                v-model="editForm.project"
                name="project"
                @input="$v.editForm.project.$touch()"
                :state="projectErrors.length > 0 ? false:null"
                label="name"
                placeholder="Seleccione el proyecto"
                :options="projects"
                :reduce="projects => projects.id">
                <span slot="no-options">
                  No se encontraron opciones
                </span>
              </v-select>
              <small class="text-danger">{{ projectErrors[0] }}</small>
            </b-form-group>
          </b-col>

          <b-col xl="12" sm="12">
          <b-form-group label="Imagen Header" label-for="logo">
          <b-form-file
              id="logo"
              type="file"
              browse-text="Formatos: jpeg - jpg" 
              accept=".jpg, .svg"
              @change="_previewLogo"
              placeholder="Selecciona una imagen" />
              
          </b-form-group>
          <b-row class="mt-2" v-if="editForm.media_logo != undefined">
            <b-col v-if="editForm.media_logo.full_url === ''" cols="12">
                <div class="preview">
                </div>
            </b-col>
            <b-col v-else cols="12">
                <div class="container" width="300" >
                    <img class="image" :src="editForm.media_logo.full_url" style="max-width:300px">
                </div>
            </b-col> 
          </b-row>
        </b-col>  
       
        <b-col cols="12" class="margin-bottom-20">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Comentario cliente</p>
            <ckeditor id="editor1" :editor="editor" v-model="editForm.comment" :config="editorConfig"
            :state="commentErrors.length > 0 ? false:null"
            @input="$v.editForm.comment.$touch()"
            ></ckeditor>
            <small class="text-danger">{{ commentErrors[0] }}</small>
          </div>
        </b-col>  
      
      </b-row> 
       
      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
          <b-form-group>
            <div>
              <b-overlay
                :show="overlayForm"
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="_onHidden()">
                <b-button block
                  ref="button"
                  :disabled="overlayForm"
                  variant="primary"
                  @click.prevent="_updateComments()">
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                  <span class="align-middle">
                  Guardar
                  </span>
                </b-button>
              </b-overlay>
            </div>
          </b-form-group>
        </div>
      </b-row>
    </b-form>
  </div>
</template>

<script> 
  import {mapActions, mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  
  export default{
    name: 'CommentsFormEdit',
    props: ['commentsingle'],
    components:{
    },
    mounted(){
    },
    data() {
      return {
        overlayForm: false,
        editForm: {
          id: '', 
          name: '',
          comment: '',
          project: '',
          media_logo: {full_url:''},
          logo: '',
        },
        editor: ClassicEditor,
        editorConfig: {
          toolbar: [
            'heading', '|', 'bold', 'italic', '|' , "undo", "redo" , 'link', 'bulletedList', 'numberedList', 'blockQuote',
            'insertTable' , 'tableColumn', 'tableRow', 'mergeTableCells',
          ],
          height: "500px"
        },
      }
    },
    validations: {
      editForm: {
        name: {
          required,
        },
        project: {
          required,
        },  
        comment: {
          required,
        },
      },
    },
    beforeMount() {
      this._prefill()
    },
    computed: {
      ...mapGetters('profile',['projects']),
      nameErrors () {
        const errors = []
        if (!this.$v.editForm.name.$dirty) return errors
        !this.$v.editForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      projectErrors () {
        const errors = []
        if (!this.$v.editForm.project.$dirty) return errors
        !this.$v.editForm.project.required && errors.push('El proyecto es obligatorio')
        return errors
      },
      commentErrors () {
        const errors = []
        if (!this.$v.editForm.comment.$dirty) return errors
        !this.$v.editForm.comment.required && errors.push('El cuerpo es obligatorio')
        return errors
      },
 
    },
    methods: {
      ...mapActions('comments',['updateComments']),
      async _updateComments() {
        this.$v.editForm.$touch()
        if (!this.$v.editForm.$invalid) {
          this.overlayForm = true
          await this.updateComments(this.editForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comentario modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            
            this.$router.push({ name: 'comments'})
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el comentario',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _prefill(){
        this.editForm.id = this.commentsingle.id
        this.editForm.name = this.commentsingle.name
        this.editForm.comment = this.commentsingle.comment
        this.editForm.project = this.commentsingle.project.id

        if (this.commentsingle.media != null) {
          console.log(this.commentsingle.media.full_url)
          this.editForm.media_logo.full_url = this.commentsingle.media.full_url
        }
      },
      _onHidden() {
        if(this.$refs.button != null){
          this.$refs.button.focus()
        }
      },
      _previewLogo(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.editForm.media_logo.full_url = URL.createObjectURL(event.target.files[0])
            this.editForm.logo = event.target.files[0]
          }else{
            document.getElementById("logo").value = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      },
    }
  }
</script>
<style>
.ckeditor {
      height: 400px;
      width: 100%;
  }
  .ck-content {
      height:350px;
  }
  .margin-bottom-20{
    margin-bottom:30px ;
  }.divInfo {
        border-radius: 10px
    }
</style>